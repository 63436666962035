import { useAuth0 } from '@auth0/auth0-react'
import React, { FC, useMemo } from 'react'

import { CategoryPageTemplateQuery } from '../../../graphql/gatsby'
import useSiteMetadata from '../../../hooks/useSiteMetadata'
import { orderByLabelStatusThenNameAsc, orderByNameAsc, useProducts } from '../../../lib/products'
import Products from './Products'

export interface ProductsContainerProps {
  magentoCategory?: CategoryPageTemplateQuery['magentoCategory']
}

const ProductsContainer: FC<ProductsContainerProps> = ({ magentoCategory }) => {
  const { isAuthenticated } = useAuth0()
  const { featureFlags } = useSiteMetadata() ?? {}
  const indicateLabelStatus = useMemo(
    () => !!featureFlags?.productLabelStatus && isAuthenticated,
    [isAuthenticated, featureFlags],
  )
  const { query, pagination, listing } = useProducts({
    defaultOrderBy: featureFlags?.productLabelStatus
      ? orderByLabelStatusThenNameAsc
      : orderByNameAsc,
    magentoCategory,
  })

  // fallback to previous data while loading if available
  const data = query.loading ? query.data ?? query.previousData : query.data

  return (
    <Products
      loading={query.loading}
      magentoCategory={magentoCategory}
      listing={listing}
      pagination={pagination}
      productCount={data?.faceted?.meta.count.all}
      products={data?.faceted}
      showLabelStatusIndicators={!!data?.faceted && indicateLabelStatus}
    />
  )
}

export default ProductsContainer
