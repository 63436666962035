import React from 'react'

import { DispensaryPageAdapter } from '../components/DispensaryPageAdapter'
import { LoadingPage, LoadingPageProps } from '../components/LoadingPage'

/**
 * Simple higher order component to wrap a page with DispensaryPageAdapter
 */
export const withDispensaryPageAdapter = <TProps extends Record<string, unknown>>(
  PageToWrap: React.ComponentType<TProps>,
  loadingPageComponent: React.ComponentType<LoadingPageProps> = LoadingPage,
): React.FC<TProps> => {
  const WithDispensaryPageAdapter: React.FC<TProps> = (props: TProps) => (
    <DispensaryPageAdapter
      loadingPageComponent={loadingPageComponent}
      page={<PageToWrap {...props} />}
    />
  )

  return WithDispensaryPageAdapter
}
